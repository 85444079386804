<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-transition"
      max-width="550"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <div class="primary white--text">
          <v-card-title>
            แก้ไขข้อมูล
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">ปีงบประมาณ</th>
                    <th class="text-center">งบประมาณที่ขอ <br> (ล้านบาท)</th>
                    <th class="text-center">งบประมาณที่ได้รับ <br> (ล้านบาท)</th>
                    <!-- <th></th> -->
                  </tr>
                </thead>
                <tr v-if="isLoading" class="pa-0 ma-0">
                  <td :colspan="3" class="pa-0 ma-0">
                    <v-progress-linear indeterminate></v-progress-linear>
                  </td>
                </tr>
                <tbody>
                  <tr v-for="(form, index) in editForm" :key="index">
                    <td class="px-2">
                      <v-text-field
                        v-model="editForm[index].budget_year"
                        type="number"
                        class="right-input"
                        outlined
                        dense
                        background-color="white"
                        min="0"
                        hide-details
                      ></v-text-field>
                    </td>
                    <td class="pa-0 pl-2">
                      <v-text-field
                        v-model="editForm[index].request_budget"
                        type="number"
                        class="right-input"
                        placeholder="0"
                        min="0"
                        outlined
                        dense
                        disabled
                        background-color="white"
                        hide-details
                      ></v-text-field>
                    </td>
                    <td class="pa-0 pl-2">
                      <v-text-field
                        v-model="editForm[index].budget"
                        type="number"
                        class="right-input"
                        placeholder="0"
                        min="0"
                        outlined
                        dense
                        background-color="white"
                        hide-details
                      ></v-text-field>
                    </td>
                    <!-- <td>
                      <v-icon color="primary" @click="removeBudgetYear(index)">
                        mdi-trash-can-outline
                      </v-icon>
                    </td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <!-- <v-btn
            color="blue"
            class="white--text mt-4"
            elevation="0"
            :disabled="isLoading"
            @click="addbudget"
          >
            <v-icon>mdi-plus</v-icon>
            เพิ่มรายการ
          </v-btn> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="green white--text"
            elevation="0"
            style="width: 140px"
            :disabled="isLoading"
            @click="isConfirmDialog = true"
          >
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="'ยืนยันการแก้ไขข้อมูล'"
      :isLoading="isLoading"
      @confirm="edit()"
      @cancel="closeConfirm()"
    />
    <ConfirmDialog
      v-model="isConfirmDeleteDialog"
      :dialog-title="'ยืนยันการลบข้อมูล ?'"
      :dialog-icon="'mdi-close-circle-outline'"
      :dialog-icon-color="'primary'"
      @confirm="confirmDelete()"
      @cancel="isConfirmDeleteDialog = false"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    value: Boolean
  },
  watch: {
    value (newVal, oldVal) {
      console.log(newVal)
      if (newVal) {
        this.getBudgetAllocationList()
      }
      this.dialog = newVal
    }
  },
  data: () => ({
    dialog: false,
    editForm: [],
    isConfirmDialog: false,
    isConfirmDeleteDialog: false,
    indexDel: null,
    isLoading: false
  }),
  mounted () {
    this.getBudgetAllocationList()
  },
  methods: {
    async getBudgetAllocationList () {
      this.isLoading = true
      this.editForm = []
      const budgetList = await this.$store.dispatch(
        'budget/getBudgetAllocationList'
      )
      this.editForm = budgetList
      this.isLoading = false
    },
    close () {
      this.$emit('updated')
      this.$emit('close')
    },
    closeConfirm () {
      this.isConfirmDialog = false
    },
    addbudget () {
      this.editForm.push({ budget_year: '', amount: '', justAdd: true })
    },
    async edit () {
      this.isLoading = true
      for (const form of this.editForm) {
        const payload = {
          budgetYear: form.budget_year,
          budget: form.budget,
          requestBudget: form.request_budget
        }
        if (payload.budgetYear && payload.budget) {
          await this.$store.dispatch(
            'budget/setBudgetAllocationByYear',
            payload
          )
        }
      }
      this.closeConfirm()
      this.$emit('updated')
      this.$emit('close')
      this.isLoading = false
    },
    removeBudgetYear (index) {
      this.indexDel = index
      this.isConfirmDeleteDialog = true
    },
    async confirmDelete () {
      const delItem = this.editForm[this.indexDel]
      if (delItem.justAdd) {
        this.editForm.splice(this.indexDel, 1)
        this.isConfirmDeleteDialog = false
      } else {
        const result = await this.$store.dispatch(
          'budget/delBudgetAllocationByYear',
          { budgetYear: delItem.budget_year }
        )
        if (result) {
          this.editForm.splice(this.indexDel, 1)
          this.isConfirmDeleteDialog = false
        }
      }
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
