<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card max-width="350">
        <v-card-text class="pt-3" style="font-size: 16px">
          <div class="d-flex justify-center">
            <v-icon size="100" :color="dialogIconColor">
              {{ dialogIcon }}
            </v-icon>
          </div>
          <h3 class="d-flex justify-center">
            {{ dialogTitle }}
          </h3>
        </v-card-text>

        <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            elevation="0"
            style="width: 140px"
            :disabled="isLoading"
            @click="cancel"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn
            class="blue white--text"
            elevation="0"
            style="width: 140px"
            :disabled="isLoading"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    dialogIcon: {
      type: String,
      default: 'mdi-alert-outline'
    },
    dialogIconColor: {
      type: String,
      default: 'orange'
    },
    confirmButtonText: {
      type: String,
      default: 'ยืนยัน'
    },
    cancelButtonText: {
      type: String,
      default: 'ยกเลิก'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dialog = newVal
      }
    }
  },
  methods: {
    confirm () {
      // this.dialog = false
      this.$emit('confirm')
    },
    cancel () {
      this.dialog = false
      this.$emit('cancel')
    }
  }
}
</script>
