<template>
  <div>
    <div class="pa-4">
      <div class="d-flex">
        <h2 class="primary--text font-weight-regular">รายการงบประมาณ</h2>
        <v-btn
          v-if="$store.getters.edit_budget"
          color="green"
          class="white--text ml-4"
          @click="editBudgetYearDialog = true"
        >
          แก้ไข
        </v-btn>
      </div>
    </div>
    <div>
      <BudgetListChart ref="budgetListChart" />
    </div>
    <EditBudgetYearDialog
      v-model="editBudgetYearDialog"
      @updated="updateBudgetYear"
      @close="editBudgetYearDialog = false"
    />
  </div>
</template>

<script>
import EditBudgetYearDialog from '@/components/budget/dialog/EditBudgetYearDialog.vue'
import BudgetListChart from '@/components/budget/BudgetListChart.vue'
export default {
  components: {
    EditBudgetYearDialog,
    BudgetListChart
  },
  data: () => ({
    editBudgetYearDialog: false
  }),
  async mounted () {
    // await this.getBudgetAllocationList()
  },
  methods: {
    // async getBudgetAllocationList () {
    //   this.editBudgetYearDialog = false
    //   const budgetList = await this.$store.dispatch(
    //     'budget/getBudgetAllocationList'
    //   )
    //   console.log(budgetList)
    // },
    updateBudgetYear () {
      this.$refs.budgetListChart.getDataGraphBudget()
      // this.getBudgetAllocationList()
    }
  }
}
</script>

<style></style>
