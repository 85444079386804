<template>
  <div class="pa-4">
    <v-card outlined class="pa-2">
      <h3 class="text-center">งบประมาณ</h3>
      <div class="mt-4" id="budgetListChart"></div>
    </v-card>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
export default {
  data: () => ({
    data: {}
  }),
  async mounted () {
    await this.getDataGraphBudget()
  },
  methods: {
    async getDataGraphBudget () {
      const data = await this.$store.dispatch('budget/getDataGraphBudget')
      this.data = data
      this.init()
    },
    init () {
      Highcharts.setOptions({
        lang: {
          noData: 'ไม่มีข้อมูล',
          decimalPoint: '.',
          thousandsSep: ','
        }
      })
      Highcharts.chart('budgetListChart', {
        chart: {
          type: 'column'
        },
        title: {
          text: '',
          style: {
            fontFamily: 'Roboto'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:,.0f}'
            }
          }
        },
        legend: {
          layout: 'vertical',
          itemMarginTop: 5,
          itemStyle: {
            fontWeight: 'Regular',
            fontFamily: 'Roboto'
          }
        },
        colors: ['#4473C5', '#ED7D31'],
        noData: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030'
          }
        },
        yAxis: {
          title: {
            text: 'งบประมาณ (ล้านบาท)'
          },
          stackLabels: {
            enabled: true,
            style: {
              fontFamily: 'Roboto'
            }
          }
        },
        ...this.data
      })
    }
  }
}
</script>

<style></style>
